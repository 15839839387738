div.container {

    background: var(--grey-1);
    border: 2px solid var(--blue-6);
    position: absolute;
    left: -2px;
    top: calc(100% + 2px);
    padding: 17.5px 0 7.5px;
}

div.dropdownOptionContainer {
    display: grid;
    /* justify-content: space-between; */
    grid-template-columns: 1fr 20px;
    margin: 0 30px;
    padding: 12.5px 0;
    width: 75vw;
}

div.dropdownOptionContainer.optionActive div.filterText {
    color: var(--orange-6);
}

div.dropdownOptionContainer.optionActive div.optionArrow {
    transform: rotate(90deg);
    color: var(--orange-6);
}

div.filterText {
    font-size: 16px;
    grid-column: 1 / 2;
    text-align: left;
    text-transform: capitalize;
}

div.optionArrow {
    grid-column: 2 / 3;
}

div.suboptionsAllContainer {
    grid-row: 2 / 3;
    text-align: left;
}

div.suboptionSingleContainer {
    margin: 20px 0 0 10px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}