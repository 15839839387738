div#sectionContainer {
    /* background-color: var(--grey-8); */
    /* background-color: var(--orange-5); */
    background-color: var(--grey-7);

    text-align: center; 
    overflow: auto;
}

div#sectionContainer h1 {
    color: var(--grey-1);
    text-align: center; /*not sure why this is necessary here more than in other places*/
    margin-top: 15px;
}

/* the purpose of this container is to ensure that the grid is only as big as needed, 
and thus remains centered. display: grid by default stretches to fill its container,
so we are opting to wrap it in another container that automatically fits its content
rather than give it a fixed width. */
div.sizingContainer {
    /* inline-flex and inline-block also work here */
    display: flex;
}

div#imageGridContainer {
    /* width: 1200px; */
    /* height: 800px; */
    --unit-size: calc(100vw * .30);
    display: grid;
    
    grid: repeat(8, var(--unit-size)) / repeat(3, var(--unit-size));
    margin: 30px auto 0;
    box-sizing: border-box;
    position: relative;
}

/* #imageGridContainer img {
    /* border: 2px solid #FDFDFD; */
    /* outline: 2px solid var(--grey-1);
    box-sizing: border-box; * /
} */

img.gridPicItem {
        /* border: 2px solid #FDFDFD; */
    /* use round number for border, smaller number for outline */

    outline: 2px solid var(--grey-1);
    box-sizing: border-box;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    cursor: pointer;
    transition: outline .6s;
}

/* see https://css-tricks.com/animating-border/ . I decided to just use outline anyways. */
img.gridPicItem:hover {
    outline: 2px solid var(--orange-5);
    z-index: 10;
}

/* .twoByTwo {
    grid-area: span 2 / span 2;
    height: calc(100% - 2px);
}

.oneByOne {
    grid-area: span 1 / span 1;
}

.twoByOne {
    grid-area: span 1 / span 2;
}

.threeByThree {
    grid-area: span 3 / span 3;
}

.oneByTwo {
    grid-area: span 2 / span 1;
} */

img._1 {
    grid-area: span 2 / span 2;
}

img._2, img._3, img._5, img._6, img._7, img._11, img._12, img._13 {
    grid-area: span 1 / span 1;
}

img._4, img._9 {
    grid-area: span 1 / span 2;
}

img._8 {
    grid-area: span 2 / span 1;
}

img._10 {
    grid-area: span 3 / span 3;
}

/* img#y_shwekey {
    grid-row: 3 / -1;
    grid-column: 3 / 5;
} */

.viewArtistsBtn {
    margin: 30px auto;
}

@media screen and (min-width: 600px) {
    div#imageGridContainer {

        /* unit1 is the square shape. in tablet view, it is 200x200. */
        --unit1-size: calc(100vw * .195);
        /* unit 2 is the rectangle. in tablet view, it is 324x200. 324 is 1.62 bigger than 200.*/
        --unit2-size: calc(var(--unit1-size) * 1.62);

        display: grid;
        
        /* grid: repeat(3, var(--unit1-size)), var(--unit2-size) / repeat(5, var(--unit1-size)); */
        /* I just split it into two lines to make it simpler */
        grid-template-columns: var(--unit1-size) var(--unit1-size) var(--unit1-size) var(--unit2-size);
        grid-template-rows: repeat(5, var(--unit1-size));
    }

    img._1, img._10 {
        grid-area: span 2 / span 2;
    }
    
    img._2, img._3, img._4, img._6, img._7, img._8, img._9, img._11, img._12, img._13 {
        grid-area: span 1 / span 1;
    }
    
    img._5 {
        grid-area: span 2 / span 1;
    }

}


@media screen and (min-width: 1000px) {
    div#imageGridContainer {

        /* unitD is the square shape. in desktop view, it is 200x200. */
        --unitD-size: calc(100vw * .138);

        display: grid;
        grid: repeat(4, var(--unitD-size)) / repeat(6, var(--unitD-size));
    }

    img._1, img._6, img._9 {
        grid-area: span 2 / span 2;
    }
    
    img._2, img._4, img._5, img._7, img._8, img._10, img._11, img._12 {
        grid-area: span 1 / span 1;
    }
    
    img._3, img._13 {
        grid-area: span 1 / span 2;
    }

}