div.autoScrollerContainer {
    position: fixed;
    z-index: 1; /* This is currently intended to be the same as the span.songChord in SongChordPage, so it doesn't look weird. */
    border: 3px solid blue;
    bottom: 100px;

    /* special trick for centering a fixed element: https://css-tricks.com/quick-css-trick-how-to-center-an-object-exactly-in-the-center/ */
    left: 50%;
    transform: translateX(-50%);
    /* right: 0;
    max-width: 350px;
    margin: auto; */

    border-radius: 4px;
    border: 1.4px solid var(--grey-3);
    background-color: var(--grey-1);

    padding: 10px 20px;
    transition: 1s linear opacity;
}

div.autoScrollerContainer.inactive {
    opacity: 0;
}

div.autoScrollerContainer * {
    font-family: 'Karla', sans-serif;
}

div.contentContainer{
    display: flex;
    /* justify-content: space-around; */
}

div.speedLabel {
    font-size: 24px;
    font-weight: normal; /* for some reason, need to override css from a different module */
}

div.speedControlContainer {
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: normal; /* for some reason, need to override css from a different module */
}

div.speedButton {
    border: 1px solid var(--grey-5);
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; 
}

div.speedButton:active {
    background: var(--blue-2);
}

div.scrollNumber {
    margin: 0 3px;
    width: 36px;
    text-align: center;
}

button.scrollButton {
    margin-left: 30px;
    color: var(--green-8);
    border: 1px solid var(--green-8);
    background-color: inherit;
    font-size: 18px;
    padding: 5px 20px;
    border-radius: 4px;
    width: 83px;
}