/* 
Default (first rule) is for smallest screen sizes (320px - 395px). 
We had to tweak the original design for this one so it would still look nice. The Figma design is starting from 395px. Note that in this version, the subscribe button bleeds a little into the View All Artists button, so be on the lookout if someone doesn't like it.
*/
button.subscribeBtn {
  background-color: #f4a506;
  width: 94px;
  height: 94px;
  border-radius: 100%;
  font-size: 13px;
  color: #fdfdfd;
  border: 2px solid var(--blue-3);
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0px;
}

@media screen and (min-width: 395px) {
  button.subscribeBtn {
    width: 100px;
    height: 100px;
    font-size: 14px;
    bottom: calc(100% + 10px);
    right: 10px;
  }
}

@media screen and (min-width: 600px) {
  button.subscribeBtn {
    width: 125px;
    height: 125px;
    font-size: 18px;
    bottom: calc(100% + 10px);
    right: 50px;
  }
}

@media screen and (min-width: 1440px) {
  button.subscribeBtn {
    bottom: calc(100% + 30px);
    right: 80px;
  }
}
