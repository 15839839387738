div.thumbSingleContainer {
    /* for the button (not needed now that we are using flex column)*/
    /* text-align: center; */
    display: flex;
    flex-direction: column;

    text-transform: capitalize; /* for the tags */
}

div.thumbSingleContainer h1 {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--grey-10);
    margin-top: 10px;
}

div.thumbSingleContainer img {
  width: 250px;
  height: 250px;
  border: 2px solid var(--grey-10);
  border-radius: 3px;
  object-fit: cover;
}

div.thumbSingleContainer div.thumbDetails {
    text-align: left;
    width: 86%;
    margin: auto;
}

div.thumbDetails div.difficulty {
    font-weight: bold;
    /* color: var(--orange-7); */
    /* text-transform: capitalize; */
    line-height: 17px;
    font-size: 14px;
    margin-top: 5px;
}

div.beginner::after {
    color: var(--green-7);
    content: 'Beginner';
}

div.intermediate::after {
    color: var(--orange-7);
    content: 'Intermediate';
}

div.advanced::after {
    color: var(--red-7);
    content: 'Advanced';
}

div.thumbDetails div.detailSingle {
    font-size: 14px;
    line-height: 17px;
    color: var(--grey-10);
    margin-top: 8px;
}


div.thumbDetails span.thumbTitle {
    font-weight: bold;
}

div.detailSingle div.includedChordsContainer,
div.detailSingle div.tagsContainer {
    display: flex;
}

div.includedChordsContainer span.thumbTitle{
    min-width: 115px;
    display: inline-block;
}

div.includedChordsContainer div.includedChords {
    /* height: 34px; */
}

div.thumbSingleContainer button {
    margin-top: 23px;
    height: 49px; /*back to 39 for desktop*/
    align-self: center;
}