div.container {
  background: var(--blue-2);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

form.loginForm {
  text-align: center;
  position: relative;
}

h2.header {
  margin-bottom: 10px;
}

div.row {
  height: 85px;
  display: flex;
  justify-content: stretch;
  /* margin-right: 10%; */
}

div.inputContainer {
  height: 55px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

input.input {
  border: none;
  font-size: 20px;
  padding-left: 10px;
  outline: none;
  border-radius: 5px 0 0 5px;
}

input.input:focus-within {
    background-color: var(--orange-1);
}

svg.nextButton {
  width: 75px;
  background: #b2c5fb;
  height: 100%;
  border-left: 3px solid var(--blue-5);
  border-radius: 0 5px 5px 0;
}

svg.nextButton:hover {
  cursor: pointer;
}

button.submitButton {
  width: 80%;
  font-size: 20px;
}

div.errorMessage,
div.passwordCreated {
  position: absolute;
  font-size: 20px;
  left: 0;
  right: 0;
}

div.errorMessage {
  color: var(--red-7);
}

div.passwordCreated {
  color: var(--green-8);
}
