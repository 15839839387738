nav {
  background-color: var(--blue-2);
  /* background-color: var(--blue-1); */
  
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position relative since it will be a parent of the mobile menu overlay */
  position: relative;
  font-weight: 700;
}

/* FINISH SETTING WIDTHS - container2 contents should be 90%, input 100% 
figure out how to access container width from grandchild (mobile menu)
UPDATE- widths should be good. Genius idea! we are using vw for mobile menu
and container width. change color once the mobile menu is active.
also, rename container to container1 and comment accordingly
*/

/* container1 and container2 exist because the logo and hamburger need to
be in their own container. This is because when mobile menu is open, the two
mentioned elements will have different background. Thus, they have identity
as their own container. It then made sense that the input should also be in
its own container. */

div.container1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* note that width and max width properties are to be the same of div.mobileMenu in MobileMenu.module.css */
  width: 45vw;
  max-width: 180px;
  height: 100%;
  margin-right: 10px;
}

div.container1Active {
  background-color: var(--grey-1);
  /* background-position: 0 100%; */
  /* animation: slideInBackground .6s; */
}

div.container2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 4;
}

div#menuLogo img {
  height: 40px;
  width: 40px;
}

div.hamburgerContainer {
  color: var(--blue-6);
  font-size: 38px;
  margin-right: 30px;
}

.mobileMenuActive {
  color: var(--orange-6) !important;
}

div.menuItem {
    color: var(--grey-1);
    letter-spacing: 1.25px;
}

nav input {
  background: var(--grey-1);
  /* width: 641px; */
  width: 100%;
  height: 30px;
  border-radius: 3px;
  border: transparent;
  padding-left: 10px;
  margin: 0;
  /* --container-1-width: calc() */
}

div.navSearchDiv {
  position: relative;
  width: 90%;
}

button.navMagGlass {
  background-color: transparent;
  font-size: 20px;
  height: 20px;
  color: var(--blue-6);
  position: absolute;
  right: 10px;
  top: 4px;
  /* bottom: 5px; */
  transform: rotate(-9deg);
  cursor: pointer;
}

div#navSearchDiv input::placeholder {
  color: var(--grey-5);
}

div#moving_banner {
    position: absolute;
    top: 100%;
    z-index: 1;
    background-color: var(--orange-5);
    padding: 13px 22px;
    text-align: center;
    white-space: nowrap;
    /* overflow: hidden; */
    border-radius: 4px;
    font-size: 24px;
    background: linear-gradient(90deg, rgba(255,196,89,1) 0%, rgba(255,181,44,1) 48%, rgba(244,165,6,1) 100%);
    box-shadow: 0px 13px 4px rgba(0, 0, 0, 0.25), 0px 4px 13px rgba(0, 0, 0, 0.25);
    }

/* min-width here should be consistent with the width in the Nav.js file based on the ternary */
@media screen and (min-width: 800px) {
  nav {
    /* padding: 0 23px 0 30px; */
    padding: 0 11% 0 30px;
  }

  div.navSearchDiv {
    width: 46%;
  }

  div.signInContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10%;
    max-width: 90px;
  }

  div.userIcon {
    color: var(--blue-6);
    border: 1px solid var(--blue-6);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    background-color: var(--grey-1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  div.signInContainer a {
    font-size: 12px;
    text-decoration: underline;
    color: var(--blue-6);
  }
}
