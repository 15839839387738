div.container {
    position: relative;
}

/* Note that although the class is .checkbox, some of them are actually radio buttons. */

input.checkbox {
    display: inline-block;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    margin: 0;
}

input.checkbox + span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    /* background: yellow; */
}

input.checkbox:checked + span {
    /* background: rgba(85, 136, 224, .4); */
    background: rgba(255, 196, 89, .6);
    opacity: .4;
}

input.checkbox + span + div {
    z-index: 10;
}

input.checkbox::after {
    content: '';
    /* background: yellow; */
}