div.container {
    display: flex;
    justify-content: center;
}

table.table {
    max-width: none;
    min-width: auto;
}

form.formContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    width: 400px;
    position: relative;
    padding-bottom: 120px; /* Same as submit buttons div */
    background: hsl(204deg 67% 38%);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

div.inputSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

form.formContainer input:not([type=radio]),
form.formContainer textarea {
    border: transparent;
}

/* hopefully the design still looks good, just had to make it not global */
div.inputSection input:not([type=radio]):not([type=checkbox]),
div.inputSection select {
    font-size: 18px;
    margin-bottom: 3px;
    height: 43px;
    border-radius: 12px;
    padding-left: 11px;
    background: hsl(205deg 51% 48%);
    caret-color: var(--grey-1);
    color: var(--grey-1);
    font-weight: bold;
    box-shadow: 
        inset 6px 6px 6px hsl(205deg 51% 40%), 
        inset -6px -6px 6px hsl(205deg 51% 61%);
}

div.inputSection input:focus:not(input[type=file]),
div.inputSection select:focus,
div.inputSection input[type=file]:focus ~ label {
    border: hsl(205deg 90% 22%) 3px solid !important;
}

div.inputSection select,
div.inputSection input::placeholder,
div.inputSection select:invalid {
    color: rgb(174, 206, 229);
    font-weight: 100;
}

div.inputSection input[type=file] {
    height: 0;
    opacity: 0;
}

div.inputSection label#albumCoverLabel {
    color: hsl(204deg 81% 19%);
    font-weight: 100;
    padding: 0.5em;
    border-color: transparent;
    background-color: #66b2e3;
    border-radius: 3px;
    width: 200px; /* same as preview image */
    height: 41px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

form.formContainer input[type=radio],
form.formContainer input[type=radio] {
    cursor: pointer;
}

form.formContainer input[type=radio] {
    margin-right: 3px;
}

form.formContainer button {
    border: 1px solid black;
    border-radius: 3px;
    font-size: 16px;
    padding: 5px 0;
}

form.formContainer textarea {
    resize: none;
    font-family: monospace;
    padding: 5px;
}

div.inputSection label:first-child {
    font-weight: bold;
}

span.reqStar {
    color: var(--red-7);
}

img.albumImagePreview {
    height: 200px;
    width: 200px;
    margin: auto;
    /* border: 2px solid var(--blue-8); */
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.inputSection input[type=checkbox]:focus,
div.inputSection input[type=radio]:focus {
    outline: red 3px solid;
}

.choiceContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* width: ; */
    /* margin: auto; */
}

.choiceSingle {
    align-items: center;
    text-transform: capitalize;
}

/* we can leave it here for now even though it's global */
input[type=checkbox] {
    text-align: left;
    margin-right: 5px;
}

textarea.chordsLyrics,
textarea.variations {
    height: 15em;
}

textarea.promoInfo {
    height: 10em;
}

div.tempoChildContainer {
    display: flex;
    justify-content: space-between;
}

input[type=number].tempo {
    width: 3em;
}

div.beatsPerLine {
    grid-template-columns: repeat(4, 1fr);
}

div.downUpContainer {
    display: flex;
    justify-content: space-around;
}

div.downUp {
    flex-direction: column;
}

div.songSegmentDivider {
    height: 3px;
    width: 100%;
    background-color: black;
    margin: 20px 0;
}

div.songSegmentNumber {
    text-align: center;
}

div.repeatedStatus {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

div.addSegmentInputContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    margin: auto;
    margin-bottom: 20px;
}

div.addSegmentInputContainer input[type=number] {
    width: 40px;
    font-size: 16px;
}

div.addSegmentInputContainer button {
    font-size: 20px;
    padding: 2px 3px;
}

div.commentsContainer {
    margin-bottom: 20px;
}

div.commentsContainer h3 {
    margin-bottom: 10px;
}

div.commentsContainer textarea {
    width: 100%;
}

div.submitBtnsContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 120px;
    width: 200px;
    max-width: 20vw;
    justify-content: space-between;
    margin-bottom: 20px;
}

div.submitBtnsContainer button {
    font-size: 20px;
    padding: 5px 9px;
}