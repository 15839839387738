div.container {
    position: relative;
    display: flex;
    min-height: 140px;
    background: linear-gradient(135deg, #7db9e8 0%,#afd9f5 43%,#207cca 66%,#207cca 66%,#1e5799 100%);
    /* background: linear-gradient(125deg, rgba(236,244,252,1) 38%, rgba(180,206,236,1) 57%, rgba(37,98,227,1) 72%); */

}

img.heroImage {
    width: 100%;
    min-height: 140px;
}

h1.header {
    position: absolute;
    font-weight: bold;
    left: 4.5%;
    top: 50px;
}

h1.allChords {
    color: var(--grey-10);
}

h1.searchResults {
    color: var(--grey-1);
    text-shadow: 
        1px 1px var(--grey-10), 
        -1px 1px var(--grey-10), 
        1px -1px var(--grey-10), 
        -1px -1px var(--grey-10);
}

@media screen and (min-width: 600px) {
    h1.title {
        left: 10%;
    }
}

@media screen and (min-width: 1440px) {
    h1.title {
        left: 13.5%;
    }
}