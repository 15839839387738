div.mobileContainer {
  display: flex;
  margin: 30px auto 31px;
  align-items: center;
  position: relative;
}

button.mobileFilterBtn {
  padding: 15px 10px;
  font-size: 16px;
  height: 49px;
  /* background: var(--blue-6); */
  
  color: var(--grey-1);
  background: var(--blue-2);

  /* font-weight: 700; */

  border-radius: 1px;
  margin-left: 4.5%;
  position: relative;
}

div.sortByBtn {
  text-decoration: underline;
  margin-left: 9.66%;
  cursor: pointer;
  font-size: 18px;
  position: relative;
}

div.mobileSortByBtn.sortByActive {
  color: var(--orange-6);
}

/* styles for tablet and desktop filter display 
"main" refers to tablet and desktop
*/

/* this width should match the ternary from the useWindowData hook in FilterOptions.js around line 53 */
@media screen and (min-width: 770px) {
  div.sortByBtn {
    margin-left: 0;
  }
}

div.mainContainer {
  margin: 30px 1.4% 50px; /*40px is subject to change*/
  display: flex;
  /* gap: 30px; */
  justify-content: space-around;
  align-items: center;
}

button.mainFilterButton {
  height: 39px;
  min-width: 107px;
  /* background: var(--blue-6); */

  background: var(--blue-2);
  color: var(--grey-1);
  /* letter-spacing: 1.25px; */

  /* font-weight: 700; */

  border-radius: 1px;
  padding: 0 10px;
  font-size: 16px;
  position: relative;
  text-transform: capitalize;
}

div.dropdownContainer {
  max-height: 300px;
  overflow-y: auto;
  background: var(--grey-1);
  border: 2px solid var(--blue-5);
  position: absolute;
  /* special calculations to make it look perfect: */
  width: calc(100% + 4px); /* only way to get it to include parent border*/
  top: 104%;
  left: -2px;
}

/* ******* width doesn't seem to be big enough - intermediate is overflowing */
div.option {
  padding: 10px 3px 10px 10px;
  text-align: left;
  font-size: 14px;
  word-break: break-word;
}

div.option:hover {
  background: var(--blue-2);
}

@media screen and (min-width: 915px) {
  div.mainContainer {
    justify-content: flex-start;
    gap: 30px;
    margin-left: 10%;
  }
}

@media screen and (min-width: 1100px) {
  div.sortByBtn {
    margin-left: 100px;
  }
}
