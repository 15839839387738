/* Table Styles */

table.flTable * {
    text-transform: capitalize;
}

table.flTable {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    /* width: 100%; */
    margin: 10px auto 70px;
    max-width: 90%;
    min-width: 85%;
    white-space: nowrap;
    background-color: var(--grey-1);
    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
}

table.flTable tr:nth-child(even) {
    background: var(--blue-1);
}

table.flTable thead th {
    color: var(--grey-1);
    background: var(--blue-3);
    cursor: pointer;
    text-transform: capitalize;
}

table.flTable thead th:nth-child(odd) {
    color: var(--grey-1);
    background: var(--blue-7);
}


table.flTable th, .flTable td {
    text-align: center;
    padding: 8px;
}

table.flTable td {
    /* border-right: 1px solid #f8f8f8; */
    font-size: 12px;
}

table.flTable td a {
    color: var(--blue-6);
    font-weight: bold;
}
