footer {
    background-color: var(--blue-2);
    /* background-color: var(--blue-1); */
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; /* This is because the subscribe button is positioned absolutely from the footer */
}

footer * {
    color: var(--grey-1);
    
}

footer #logo {
    margin: 10px 0 6px;
    height: 40px;
    width: 40px;
}

footer #contact {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    margin: 0; /*remove once we have done margin 0 on everything*/
    display: inline-block;
    /* issue: ensure that only text is clickable, not space on either side */
}

footer #copyright {
    font-size: 10px;
    padding: 7px 0 5px;
}