/* #contentContainer {
    margin: 0 20px 55px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-evenly;
    text-align: center;
    row-gap: 40px;
  } */

  div#contentContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 50px;;
    max-width: 250px;
    margin: 0 auto 20px;
  }

  div.pageNumberContainer {
      display: flex;
      justify-content: center;
      margin: 50px auto;
  }

  div.numbersDiv {
      margin: 0 10px;
  }

  span.arrowDisabled {
    color: var(--grey-6);
  }

  /* this can use more work getting the gaps set up properly */
  @media screen and (min-width: 700px) {
    div#contentContainer {
        grid-template-columns: 1fr 1fr;
        row-gap: 50px;
        column-gap: 50px;
        max-width: 550px;
      }
  }

  @media screen and (min-width: 900px) {
    div#contentContainer {
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 850px;
      }
  }

  @media screen and (min-width: 1200px) {
    div#contentContainer {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 10px;
        row-gap: 41px;
        max-width: 1250px;
      }
  }

  @media screen and (min-width: 1300px) {
    div#contentContainer {
        column-gap: 70px;
      }
  }