/* Note: An observant user may notice that there is a lot of space between the input field(s) and the send/submit button on larger screens. The reason is because we need that space for smaller screens and we didn't make any changes for the larger screens. This is something we may want to correct later, but it would take a little bit of thinking to have a uniform style for both success and error message (given that we are using absolute positioning for the response message and that the text of the different messages are not of the same length). 
A possible idea would be to use natural positioning for the response message and simply replace the submit button with the response when there is one. E.g. {!response ? <input type=submit etc.> : <div>{response}</div> } */

div.overlay {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

div.content {
  position: relative;
  top: 15%;
  width: 100%;
  /* height: 312px; */
  background: #fdfdfd;
  box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  margin: auto;
  --left-margin: 6%;
}

span.exitIcon {
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
}

div.subHeader {
  width: 89%;
  margin-left: var(--left-margin);
  align-self: flex-start;
}

h1.tagline {
    text-align: center;
}

form.form {
  width: 100%;
  text-align: center; /* this rule is just to target the submit button */
}

/* a div for the label and the input */
div.inputSection {
  /* width: 452px; */
  margin-left: var(--left-margin);
  margin-right: var(
    --left-margin
  ); /* even though this is a left margin, here we need it for both sides so it looks centered */
  display: flex;
  justify-content: space-between;
}

div.inputSection label {
  width: 28%;
  text-align: left;
  /* font-size: 18px; for bigger screens */
}

div.inputSection input,
div.inputSection textarea {
  width: 72%;
}

div.content input[type="submit"] {
margin-top: 65px;
  cursor: pointer;
  margin-bottom: 20px;
}

div.messageResponse {
  position: absolute;
  left: 0;
  right: 0;
  /* max-width: 80%; not sure if this ever does anything for us */
  width: fit-content;
  margin: auto;
  bottom: 66px;
  text-align: center;
  border-radius: 3px;
  display: inline-block;
  padding: 0.25em 0.75em;
}

div.error {
  color: var(--red-7);
  border: 2px solid var(--red-7);
}

div.success {
  color: var(--green-9);
  border: 2px solid var(--green-9);
}

@media screen and (min-width: 600px) {
  div.content {
    width: 80%;
    max-width: 627px;
    --left-margin: 87px;
  }

  div.subHeader {
    width: 100%;
    font-size: 18px;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  div.inputSection {
      justify-content: flex-start;
  }

  div.inputSection label {
    width: 162px;
    font-size: 18px;
  }

  div.inputSection input,
  div.inputSection textarea {
    max-width: 250px;
  }
}
