div.headerContainer {
  background-color: var(--grey-1);
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px; /* this can eventually change to a top margin on whatever the last element ends up being */
  margin-top: 50px;
  width: 275px;
  position: relative; /* for the All Chords link */
}

img.albumCoverImg {
  height: 275px;
  width: 275px;

  border-radius: 3px;
  border: 2px solid var(--grey-10);
}

h1.songName {
  font-size: 48px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
}

div.allChordsLink {
  text-decoration: underline;
  position: absolute;
  right: 0;
  bottom: 14px;
  cursor: pointer;
}

div.bodyContainer {
  background-color: var(--blue-1);
  overflow: auto;
}

div.chordsLyricsContainer {
  background-color: var(--grey-1);
  border-radius: 20px;
  margin: 20px 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative; /* for the auto scroller */

  --line-height: 32px;
}

h1.songTitle {
  font-size: 48px;
  margin-top: 30px;
  text-align: center;
}

h3.songCapoPlacement {
  margin-top: 10px;
}

div.chordsLyrics {
  /* white-space: pre; */
  margin-top: 30px;
  margin-bottom: 40px;
  /* font-size: 18px; */ /* font size is set in javascript */
  /* font-weight: 600; */
  /* line-height: 32px; */
  /* font-family: "Anonymous Pro", monospace; */
  line-height: var(--line-height);
  width: 100%;
}

div.songSegment {
  display: block;
  font-size: inherit;
  white-space: pre;
  font-weight: 600;
  /* width: 100%; */
  width: fit-content;
  padding: 0 min(2%, 14px);
  font-family: "Anonymous Pro", monospace;
  /* see this stackoverflow to understand the bug this is solving: 
  https://stackoverflow.com/questions/17365838/remove-leading-whitespace-from-whitespace-pre-element
  negative margin of the line height here (-32px), I had to do some css acrobatics to get it through: */
  /* margin-top: calc(var(--line-height) - (var(--line-height) * 2));  */
}

div.repeatedSegment {
  /* border-top: 3px solid var(--orange-6); */
  display: none;
}

div.scrollingActive div.repeatedSegment {
  display: block;
}

div.songVerse:not(:first-of-type), /* just in case, you know */
div.songChorus:not(:first-of-type) {
  margin-top: 30px;
}

div.scrollingActive div.songVerse,
div.scrollingActive div.songChorus {
  margin-top: 0;
  /* padding: 0 10px; */
}

div.scrollingActive div.songChorus {
  background: var(--orange-1);
  border-radius: 6px;
  margin-top: 0;
}

span.songChord {
  font-size: inherit;
  white-space: inherit;
  font-weight: 900;
  font-family: inherit;
  line-height: inherit;
  z-index: 1; /* This is currently intended to be the same as the AutoScroller, so it doesn't look weird. */
  position: relative;
  text-shadow: 0 0 0.5px var(--grey-7);
}

span.songChord::before {
  content: "";
  background-color: var(--blue-1);
  /* border: 1px solid var(--blue-6); */
  border-radius: 4px;
  position: absolute;
  height: 122%;
  width: calc(100% + 77%);
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
  transform: translate(-26%, -7%);
  z-index: -1;
}

span.songLyric {
  font-size: inherit;
  white-space: inherit;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}

@media screen and (min-width: 390px) {
  div.headerContainer {
    width: auto;
  }

  div.allChordsLink {
    right: 26px;
  }
}

@media screen and (min-width: 600px) {
  div.chordsLyricsContainer {
    margin: 30px 20px;
    padding: 0 30px;
    align-items: flex-start;
  }

  div.chordsLyrics {
    padding: 0;
  }
}

@media screen and (min-width: 700px) {
  div.headerContainer {
    flex-direction: row;
    align-items: flex-start;
  }

  img.albumCoverImg {
    max-height: 400px;
    max-width: 400px;
    height: 27vw;
    width: 27vw;
  }

  div.headerTextContainer {
    margin-left: 4.3vw;
  }
}

@media screen and (min-width: 800px) {
  div.chordsLyricsContainer {
    margin: 40px 40px;
  }
}

@media screen and (min-width: 1000px) {
  div.headerTextContainer {
    margin-left: 7vw;
  }

  div.chordsLyricsContainer {
    margin: 50px 50px;
  }

  div.allChordsLink {
    right: 7%;
    bottom: 7%;
  }
}

@media screen and (min-width: 1200px) {
  div.chordsLyricsContainer {
    margin: 50px 7vwpx;
  }
}
