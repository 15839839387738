div.draftsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    /* width: max(40%, 300px); */
    justify-content: center;
    align-items: center;
}

div.draftSingleDiv {
    margin: 10px;
    border: 3px solid var(--blue-6);
    border-radius: 4px;
    background: var(--blue-1);
    padding: 10px;
    max-width: 300px;
    text-align: center;
    cursor: pointer;
    flex: 1 1 0;
}

div.separator {
    height: 3px;
    width: 80vw;
    justify-self: center;
    align-self: center;
    background-color: var(--blue-4);
    margin: 20px 0;
}