h1.tagline {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-left: 4.5%; /* should be the same as the filter button left margin */
}

@media screen and (min-width: 600px) {
  h1.tagline {
    font-size: 48px;
  }
}

@media screen and (min-width: 915px) {
    h1.tagline {
      margin-left: 9.5%; 
    }
  }
  