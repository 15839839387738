/* this element is a child of the nav element, which is position relative */
div.mobileMenuOverlay {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100vw;
  height: calc(100vh - 60px);
  /* bug ************* - background overlay only takes up the view height.
  so if user scrolls down, he will see outside the overlay.
  1) either find a way to make it take up the whole html, or
  2) limit the overlay to only the space needed and don't change the background
    -it would be inconsistent to just remove the background color, bc where the overlay
    is will still be unclickable. it must be that if it's invisible, clicking remains
    unaffected throughout */
  background-color: rgba(39, 79, 185, 0.4); /* blue-5 in rgb form */
  z-index: 10;
}

@keyframes showMenu {
  from {
    left: -180px;
  }

  to {
    left: 0;
  }
}

@keyframes hideMenu {
  from {
    left: 0;
  }
  to {
    left: -180px;
  }
}

/* ******** change this to mobileMenuMounted, since we have another mounted class later */
.mounted {
  /* left: 0;
    top: 0; */
  animation-name: showMenu;
  animation-duration: 0.6s;
}

.unmounted {
  /* left: 0; */
  /* top: 0; */
  animation-name: hideMenu;
  animation-duration: 0.6s;
}

div.mobileMenu {
/* note that width and max width properties are to be the same of div.container1 in Nav css */
  width: 45vw;
  max-width: 180px;

  /* height: 281px; */
  background-color: var(--grey-1);
  border-radius: 0px 0px 5px 0px;
  border-top: 2px solid var(--grey-7);
  position: absolute;
}

/* separate div for the content of the mobile menu.
this is to separate the positioning styles from the
internal content styles */
div.mobileMenuContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: 83px; */
  margin: 15px 10px;
  justify-content: center;
}

/* no such thing as a hover when it comes to mobile, lol */
/* div.mobileMenuContent a:hover, */
div.mobileMenuContent a:active {
    color: var(--orange-6);
}

/* menu buttons are the icons: home and account */

div.menuButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* selecting each of the two buttons by their div */
div.menuButton {
  color: var(--blue-6);
  border: 1px solid var(--blue-6);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.homeButton {
  font-size: 30px;
}

div.acccountButton {
  font-size: 26px;
}

/* ******* for some reason, the border makes the element higher,
so there is a jump when you switch. probably need box-sizing: content-box; */
.menuButtonActive {
  border-bottom: 5px solid var(--orange-6);
}

.menuButtonActive .menuButton {
  border-color: var(--orange-6);
  color: var(--orange-6);
}

div.menuButtonContainer p,
div.menuSelect div {
  font-size: 14px;
}

div.menuSelectContainer {
  /* margin-top: 20px; out until we have icons */
  margin-left: 10px;
  grid-column: 1 / 3;
}

/* menu selects are the options: about, chords (downloads?) */
div.menuSelect {
  /* grid-column: 1 / 3; */
  /* justify-self: stretch; */
  /* align-self: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

div.menuSelect:not(:last-of-type) {
    margin-bottom: 20px;
}

/* might want a better name for this, but it's the div that holds "Chords" and the arrow(>) */
div.chordsSelectHeader {
  display: flex;
  justify-content: space-between;
}

div.chordsSelectActive {
    color: var(--orange-6);
}

div.chordsSelectActive div.chordsOptionsArrow {
    transform: rotate(90deg);
}

@keyframes showChordOptionsContainer {
  from {
    height: 0px;
  }
  to {
    height: 120px;
  }
}

@keyframes hideChordOptionsContainer {
    from {
      height: 120px;
    }
    to {
      height: 0px;
    }
  }

div.chordsOptionsContainer {
  position: relative;
  height: 120px;
  overflow: hidden;
  /* margin: 15px 0 0 30px; */
  /* margin-bottom: 15px; */
  margin-left: 30px;
  /* padding-top: 15px; */
  transform: translateY(15px); /*best way to get the offset without having a jump in the transition*/
}

div.chordsOptionsMounted {
    animation: showChordOptionsContainer 0.6s;
}

div.chordsOptionsUnmounted {
  animation: hideChordOptionsContainer 0.6s;
}

div.chordsOptionsPositionedContainer {
  position: absolute;
  margin-top: 15px;
  left: 30px;
  /* overflow: hidden; */
}

div.chordsSelectOption:not(:first-child) {
  margin-top: 20px;
}
