@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

/* #print {
  display: none;
}

@media print {
  body * :not(#print) {
    display: none;
  }

  #print {
    display: block;
  }
} */

:root {
  --grey-1: #fdfdfd;
  --grey-2: #eaeaea;
  --grey-3: #d1d5d8;
  --grey-4: #b7bbbf;
  --grey-5: #acb1b5;
  --grey-6: #8d9297;
  --grey-7: #6e787e;
  --grey-8: #4b5258;
  --grey-9: #313439;
  --grey-10: #1d1f21;

  /* --blue-1: #ecf4fc; */
  /* --blue-2: #b4ceec; */
  
  --blue-3: #5588e0;
  --blue-4: #2562e3;
  --blue-5: #274fb9;
  --blue-6: #0b2d98;
  --blue-7: #10235e;
  --blue-8: #010257;
  --blue-9: #03003e;
  --blue-10: #040021;

  --blue-1: #afd9f5;
  --blue-2: #53a8e0;
  /* --blue-6: #286083; */
  /* --blue-7: #0B7DC7; */

  --orange-1: #fff2de;
  --orange-2: #ffe3b2;
  --orange-3: #ffd385;
  --orange-4: #ffc459;
  --orange-5: #ffb52c;
  --orange-6: #f4a506;
  --orange-7: #d27600;
  --orange-8: #a64900;
  --orange-9: #5e1a00;
  --orange-10: #260300;

  --red-1: #ffc6b3;
  --red-2: #ffa78f;
  --red-3: #ff876b;
  --red-4: #ff6848;
  --red-5: #ff4c35;
  --red-6: #ec3428;
  --red-7: #df1212;
  --red-8: #bf010e;
  --red-9: #9f000d;
  --red-10: #80000e;

  --green-1: #d0fecc;
  --green-2: #a3fba7;
  --green-3: #7cf490;
  --green-4: #5aea89;
  --green-5: #3bde8b;
  --green-6: #1ecf93;
  --green-7: #0ab48c;
  --green-8: #00917e;
  --green-9: #006665;
  --green-10: #003333;
}

* {
  font-family: "Rubik";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:not(i) {
    color: var(--grey-9);
}

html {
    height: 100%;
}

body {
  margin: 0;
  background-color: var(--grey-1);
  position: relative; /* This may have originally been for the Subscribe Button, if so, no longer necessary. */
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: "Karla", sans-serif;
}

h1 {
  font-size: 36px;
  font-weight: 400;
  /* padding-top: 18px; */
}

a {
  text-decoration: none;
  color: inherit;
}

button, input[type=submit] {
  border-color: transparent;
  cursor: pointer;
}

button:disabled,
input[type=submit]:disabled {
    background: var(--grey-7);
    cursor: not-allowed;
}

form,
form:focus,
form:focus-visible,
form:focus-within,
form:active,
input,
input:focus,
input:focus-visible,
input:focus-within,
input:active,
button:focus-within,
select {
  outline: none;
  border-color: transparent;
}

/* UTILITY classes */

.hidden {
  display: none;
}

.block {
  display: block;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.capitalize {
    text-transform: capitalize;
}

/* most homepage buttons (view chord sheet, view all chords) */
.btn1 {
  /* background-color: var(--blue-6); */
  color: var(--grey-1);
  background-color: var(--blue-2);
  font-weight: 700;

  /* border-radius: 5px; */
  border-radius: 80px;
  letter-spacing: 1.25px;
  /* width: 185px; */
  height: 39px;
  padding: 10px 20px;
  font-size: 16px;
  /* transition: .6s transform, .6s background-color; */
}

/* form buttons, view all artists btn on bottom of homepage */
.btn2 {
  /* background-color: var(--blue-6); */
  color: var(--grey-1);
  background-color: var(--blue-2);
  font-weight: 700;
  letter-spacing: 1.25px;
  /* width: 185px; */
  height: 39px;
  padding: 10px 20px;
  font-size: 16px;
  
  /* border-radius: 3px; */
  border-radius: 80px;
  /* transition: .6s transform, .6s background-color; */
}

/* .btn1:hover, .btn2:hover {
    transform: translateY(2px);
    background-color: var(--blue-4);
} */

/* need to target the children of the button to override * rule above
since the text is from the ::after of the i element */
.btn1 *, .btn2 * {
    color: var(--grey-1);
}

/* only apply box-shadow to buttons once a certain width is reached */
@media screen and (min-width: 500px) {
    .btn1 {
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .btn2 {
        box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.25);
      }
}

.input1 {
  background: var(--grey-1);
  border: 2px solid var(--blue-3);
  border-radius: 3px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  /* width: 250px; */ /*removed this as a global rule for now*/
  padding: 4px 7px;
}

.input1:hover {
    border: 2px solid var(--orange-4);
}

.input1:focus,
.input1:focus-within,
.input1:active,
.input1:focus-visible {
    border: 2px solid var(--blue-3)!important;
}

.input1::placeholder {
  color: var(--grey-4);
}

.input1.error {
    border: 2px solid var(--red-7);
    color: var(--red-7);
}

.input1:disabled {
    background-color: var(--grey-2);
    border: 2px solid var(--grey-7);
}

/* selecting those inputs that are not textareas */
input.input1 {
    height: 30px;
}

textarea.input1 {
    height: 150px;
}

*.beginner::after {
    color: var(--green-7);
    content: 'Beginner';
}

*.intermediate::after {
    color: var(--orange-7);
    content: 'Intermediate';
}

*.advanced::after {
    color: var(--red-7);
    content: 'Advanced';
}

.gridContainer {
  display: grid;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
