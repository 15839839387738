/* DEFAULT STYLING - for mobile devices (mobile-first design) */
div#featuredChords {
  /* background-color: var(--grey-8); */

  /* background-color: var(--orange-5); */
  background-color: var(--grey-7);

  /* background-image: url('assets/hero/orange_texture.png'); */
  text-align: center;
  overflow: auto;
}

div#featuredChords h1 {
  color: var(--grey-1);
  font-size: 24px;
  margin-top: 10px;
}

div.mainImgContainer {
    width: 60vw;
    height: 60vw;
    position: relative;
    margin: auto;
}

img.ftrChordsMainImage {
  width: 60vw;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 30px;
  position: absolute;
}

img.ftrChordsMainImage:not(.mainImgActive) {
    opacity: 0;
    animation: fade-out-image 1s;
}

img.ftrChordsMainImage.mainImgActive {
    opacity: 1;
    animation: fade-in-image 1s;
  }

div#ftrChordsBtns {
  margin-top: 20px;
}

#ftrChordsBtns button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 10px;
  border: 1px solid;
}

#ftrChordsBtns button:not(.ftrChordsBtnActive) {
  border-color: var(--grey-10);
  background-color: var(--grey-1);
  animation: fade-out-button 1s;
}

#ftrChordsBtns button.ftrChordsBtnActive {
  background-color: var(--orange-6);
  /* background-color: var(--blue-2); */
  border-color: var(--blue-5);
  animation: fade-in-button 1s;
}

div#miniDisplaysContainer {
  display: flex;
  flex-direction: column;
  width: 93vw;
  margin: 30px auto; /*DESKTOP DIFFERENT*/
  font-size: 16px;
}

div.featuredChordSingle {
  background-color: var(--grey-1);
  height: 70px;
  /* definitely for mobile, maybe for tablet and desktop also */
  width: 100%;

  position: relative;
  /* not sure if this is just for the desktop or also the tablet: */
  align-self: center;
  cursor: pointer;
  border-radius: 5px;
}

div.featuredChordSingle:not(.ftrChordsSingleActive) {
  border: 3px solid transparent;
  animation: fade-out-border 1s;
}

div.featuredChordSingle:nth-of-type(2) {
  /* DESKTOP - margin: 20 0 */
  margin: 10px 0;
}

div.ftrChordsSingleActive {
  border: 3px solid var(--orange-6);
  /* border: 3px solid var(--blue-2); */
  animation: fade-in-border 1s;
}

div.containerDiv {
  width: 92.2%;
  display: flex;
  /* justify-content: space-between; */

  position: absolute;
  left: 15px;
  /* DESKTOP - left: 20px */
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

div.featuredChordSingle img {
  height: 50px;
  width: 50px;
  margin-right: 10px;

  border: 2px solid var(--grey-10);
  border-radius: 3px;

  /* outline: 2px solid var(--grey-10); */
}

div.ftrChordsSongInfoDiv {
  text-align: left;
  /* OVERFLOW ELLIPSIS NOT WORKING ************ 
  apparently you need to have pixel width for it to work.
  some say that can be solved by using calc(37%), which
  renders to a pixel width. In any case, it doesn't seem 
  to be working here for some reason.*/
  width: 37%;
  /* margin: auto; */
  /* probably needs some work to show up better on smaller screens: ********** */
  margin-right: min(10px, 2%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

div.ftrChordsSongInfoDiv * {
  margin-bottom: 2%;
}

.ftrChordsSongInfoDiv .songName {
  font-size: 1.125em;
  /* margin: 24px 0 15px; */
}

.ftrChordsSongInfoDiv .artistName {
  font-size: 0.875em;
}

.ftrChordsSongInfoDiv .artistName span {
  font-weight: bold;
}

div.featuredChordSingle button {
  padding: 15px 0px;
  height: 49px;
  min-width: 120px;
  align-self: flex-end;
  margin-left: auto;
  /* box-shadow: none; */
  font-size: 1em;
  flex-grow: 2;
}

/* setting the content - the span is there so we can use ::after selector on it */
div.featuredChordSingle button span::after {
  content: "View Sheet";
}

/* changing the content when there's enough room */
@media screen and (min-width: 568px) and (max-width: 866px) {
  div.featuredChordSingle button span::after {
    content: "View Chord Sheet";
  }
}

/* TABLET - greater than 866px */

@media screen and (min-width: 866px) {
  #featuredChordsContentDiv {
    display: grid;
    grid-template-columns: 375px 1fr 6fr;
    grid-template-rows: repeat(3, 125px) 25px 20px;
    margin: 30px 20px;
  }

  div.mainImgContainer {
    width: 375px;
    height: 375px;
    position: relative;
    margin: auto;
}

  img.ftrChordsMainImage {
    height: 375px;
    width: 375px;
    /* max-width: 450px;
        width: 40vw; */
    grid-row: 1 / 4;
    grid-column: 1 / 2;
    margin: 0;
  }

  div#ftrChordsBtns {
    /* margin-top: 10px; */
    margin: 0;
    grid-column: 1 / 2;
    grid-row: 5 / 6;
  }

  line.ftrChordsConnLine {
    border: 1px solid ;
    /* border: 1px solid var(--blue-2); */
    height: 0;
    /* width: 150px; */
    grid-column: 2 / 3;
    align-self: center;
  }

  line.ftrChordsConnLine:nth-of-type(1) {
      grid-row-start: 1;
  }

  line.ftrChordsConnLine:nth-of-type(2) {
    grid-row-start: 2;
}

line.ftrChordsConnLine:nth-of-type(3) {
    grid-row-start: 3;
}

  line.ftrChordsConnLine:not(.connLineActive) {
    border-color: transparent;
    animation: fade-out-image 1s;
  }

 line.ftrChordsConnLine.connLineActive {
    animation: fade-in-image 1s;
    border-color: var(--orange-6);
  }

  div#miniDisplaysContainer {
    margin: 0;
    width: auto;
    grid-column: 3 / 5;
    grid-row: 1 / 4;
    justify-content: space-around;
  }

  div.featuredChordSingle {
    height: 108px;
  }

  div.featuredChordSingle:nth-of-type(1) {
    grid-row: 1 / 2;
    /* border: 4px solid blue !important; */
  }
  div.featuredChordSingle:nth-of-type(2) {
    grid-row: 2 / 3;
  }
  div.featuredChordSingle:nth-of-type(3) {
    grid-row: 3 / 4;
  }

  div.featuredChordSingle img {
    height: 75px;
    width: 75px;
    margin-right: 25px;

    border: 2px solid var(--grey-10);
    border-radius: 3px;

    /* outline: 2px solid var(--grey-10); */
  }

  div.ftrChordsSongInfoDiv {
    justify-content: flex-start;
  }

  div.ftrChordsSongInfoDiv * {
    margin: 8px 0;
  }
}

/* DESKTOP - greater than 1024px */

@media screen and (min-width: 1024px) {
  div#featuredChords h1 {
    font-size: 36px;
  }

  div.featuredChordSingle button span::after {
    content: "View Chord Sheet";
  }
}

@media screen and (min-width: 1200px) {
  #featuredChordsContentDiv {
    grid-template-columns: 450px 1fr 5fr;
    grid-template-rows: repeat(3, 150px) 10px 20px;
    max-width: 1320px;
    width: 95%;
    margin: 20px auto;
  }

  div.mainImgContainer {
    width: 450px;
    height: 450px;
}

  img.ftrChordsMainImage {
    height: 450px;
    width: 450px;
  }

  div.featuredChordSingle {
    height: 132px;
  }

  div.featuredChordSingle img {
    height: 100px;
    width: 100px;
    margin-right: 8%;

    border: 2px solid var(--grey-10);
    border-radius: 3px;

    /* outline: 2px solid var(--grey-10); */
  }

  /* div.ftrChordsSongInfoDiv{
        margin-bottom: 13px;
      }

    div.ftrChordsSongInfoDiv *{
      margin-bottom: 13px;
    } */
}

@keyframes fade-in-border {
  from {
    border: 3px solid transparent;
  }

  to {
    border: 3px solid orange;
  }
}

@keyframes fade-out-border {
  from {
    border: 3px solid orange;
  }

  to {
    border: 3px solid transparent;
  }
}

@keyframes fade-in-button {
  from {
    border-color: var(--grey-10);
    background-color: var(--grey-1);
  }

  to {
    background-color: var(--orange-6);
    border-color: var(--blue-5);
  }
}

@keyframes fade-out-button {
  from {
    background-color: var(--orange-6);
    border-color: var(--blue-5);
  }

  to {
    border-color: var(--grey-10);
    background-color: var(--grey-1);
  }
}

@keyframes fade-in-image {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }

  @keyframes fade-out-image {
    from {
        opacity: 1;
    }
  
    to {
        opacity: 0;
    }
  }