/* Did a little nitpicking in this file. We have slightly different styles for the songChordPage (larger font-size and margins), so I used a different selector to target where it's from the songChordPage. */

div.thumbSingleContainer div.thumbDetails {
    text-align: left;
    width: 86%;
    margin: auto;
}

div.songChordPage *,
div.songChordPage div.thumbDetails div.difficulty {
    font-size: 14px !important; /* for some reason only working with !important selector, we should win even with specificity */
}
div.songChordPage.thumbDetails div.difficulty {
    margin-top: 9px;
    margin-bottom: 20px;
}

div.thumbDetails div.difficulty {
    font-weight: bold;
    /* color: var(--orange-7); */
    /* text-transform: capitalize; */
    line-height: 17px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 8px;
}

div.beginner::after {
    color: var(--green-7);
    content: 'Beginner';
}

div.intermediate::after {
    color: var(--orange-7);
    content: 'Intermediate';
}

div.advanced::after {
    color: var(--red-7);
    content: 'Advanced';
}

div.songChordPage.thumbDetails div.detailSingle {
    margin-top: 10px;
}

div.thumbDetails div.detailSingle {
    font-size: 14px;
    line-height: 17px;
    color: var(--grey-10);
    margin-top: 5px;
}


div.thumbDetails span.thumbTitle {
    font-weight: bold;
}

div.detailSingle div.includedChordsContainer,
div.detailSingle div.tagsContainer {
    display: flex;
}

div.includedChordsContainer span.thumbTitle{
    min-width: 115px;
    display: inline-block;
}

div.tagsContainer {
    text-transform: capitalize;
}