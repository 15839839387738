div.container {
    position: relative;
    overflow: hidden;
    /* very important, prevents the border top right from overflowing */
    height: 56.2vw;
    /* in case image fails */
    background: linear-gradient(135deg, #7db9e8 0%, #afd9f5 43%, #207cca 66%, #207cca 66%, #1e5799 100%);
}

img.mainImage {
    width: 100%;
    object-fit: cover;
    display: block;
    height: 56.2vw;
    /* in case image fails */
}

img#alttext-image {
    display: none;
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.05, 1.05);
    }

    100% {
        transform: scale(1, 1);
    }
}

button.ctaButton {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    height: 56px;
    width: 281px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 80px;
    margin: auto;
    background-color: var(--orange-5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: pulse 3s ease infinite; */
}

span.ctaText {
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--grey-1);
    /* padding: 0 49px; */
    margin-left: 9px;
    margin-right: 4px;
    vertical-align: middle;
}

svg.playArrowIcon {
    vertical-align: middle;
    height: 41px;
}

div.border_top {
    position: absolute;
    background-color: var(--blue-2);
    height: 6px;
    border-radius: 80px;
    width: 75px;
    /* top: -22px;
  right: 76px; */
    top: -19px;
    right: 73px;
}

div.border_top_right {
    position: absolute;
    width: 75px;
    height: 75px;
    border: 6px solid transparent;
    border-top: 6px solid var(--blue-2);
    border-radius: 50%;
    transform: rotate(45deg);
    /* top: -22px;
  right: -23.5px; */
    top: -19px;
    right: -20.5px;
}

div.border_ball {
    background-color: var(--blue-2);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    position: absolute;
}

div.btr_left_ball {
    top: 4px;
    left: 4px;
}

div.btr_right_ball {
    top: 4px;
    right: 4px;
}

div.border_bottom_left {
    position: absolute;
    width: 75px;
    height: 75px;
    border: 6px solid transparent;
    border-top: 6px solid var(--blue-2);
    border-radius: 50%;
    transform: rotate(225deg);
    /* bottom: -22px;
  left: -23.5px; */
    bottom: -19px;
    left: -20.5px;
}

div.bbl_left_ball {
    top: 4px;
    right: 4px;
}

div.bbl_right_ball {
    top: 4px;
    left: 4px;
}

@keyframes pulse2 {
    0% {
        box-shadow:
            0px 10px 10px var(--orange-2),
            10px 0px 10px var(--orange-2),
            0px -10px 10px var(--orange-2),
            -10px 0px 10px var(--orange-2);
        /* transform: rotate(-5deg); */

    }

    50% {
        box-shadow:
        0px 10px 10px var(--blue-1),
        10px 0px 10px var(--blue-1),
        0px -10px 10px var(--blue-1),
        -10px 0px 10px var(--blue-1);
        /* transform: rotate(5deg); */

    }

    100% {
        box-shadow:
        0px 10px 10px var(--orange-2),
        10px 0px 10px var(--orange-2),
        0px -10px 10px var(--orange-2),
        -10px 0px 10px var(--orange-2);
    /* transform: rotate(-5deg); */

    }
}

a.fullDBbutton {
    background: #edf3f7;
    padding: 5px 10px;
    position: absolute;
    right: 3vw;
    /* top: 77vh; */
    bottom: 3%;
    border-radius: 6px;
    /* box-shadow: 3px 3px 3px grey; */
    z-index: 10;
    border: 1px solid #afd9f5;
    color: #1071b2;
    animation: pulse2 3s ease infinite;
    /* overflow: hidden; */
}

@media screen and (min-width: 600px) {
    button.ctaButton {
        height: 79px;
        width: 321px;
        animation: pulse 3s ease infinite;
    }

    span.ctaText {
        font-size: 27px;
        margin-left: 14px;
        margin-right: -2px;
    }

    svg.playArrowIcon {
        height: 44px;
    }

    div.border_top {
        /* top: -22px;
        right: 76px; */
        top: -19px;
        right: 73px;
    }

    div.border_top_right {
        width: 105px;
        height: 105px;

        /* top: -22px;
        right: -23.5px; */
        top: -19px;
        right: -20.5px;
    }

    div.btr_left_ball {
        top: 8px;
        left: 9px;
    }

    div.btr_right_ball {
        top: 8px;
        right: 9px;
    }

    div.border_bottom_left {
        width: 105px;
        height: 105px;

        /* bottom: -22px;
        left: -23.5px; */
        bottom: -19px;
        left: -20.5px;
    }

    div.bbl_left_ball {
        top: 8px;
        right: 9px;
    }

    div.bbl_right_ball {
        top: 8px;
        left: 9px;
    }

    a.fullDBbutton {
        bottom: 5%;
    }

    @keyframes pulse2 {
        0% {
            box-shadow:
                0px 20px 20px var(--orange-2),
                20px 0px 20px var(--orange-2),
                0px -20px 20px var(--orange-2),
                -20px 0px 20px var(--orange-2);
            transform: rotate(-5deg);
    
        }
    
        50% {
            box-shadow:
                0px 20px 20px var(--blue-1),
                20px 0px 20px var(--blue-1),
                0px -20px 20px var(--blue-1),
                -20px 0px 20px var(--blue-1);
            transform: rotate(5deg);
    
        }
    
        100% {
            box-shadow:
                0px 20px 20px var(--orange-2),
                20px 0px 20px var(--orange-2),
                0px -20px 20px var(--orange-2),
                -20px 0px 20px var(--orange-2);
            transform: rotate(-5deg);
    
        }
    }
}

@media screen and (min-width: 960px) {
    button.ctaButton {
        height: 103px;
        /* width: 597px; */
        width: 550px;
        /* animation: pulse 3s ease infinite; */
    }

    span.ctaText {
        font-size: 38px;
        margin-left: 7px;
        margin-right: 24px;
    }

    svg.playArrowIcon {
        height: 64px;
        width: 64px;
    }

    div.border_top {
        top: -22px;
        right: 76px;
    }

    div.border_top_right {
        width: 150px;
        height: 150px;
        top: -22px;
        right: -23.5px;
    }

    div.btr_left_ball {
        top: 15px;
        left: 15px;
    }

    div.btr_right_ball {
        top: 15px;
        right: 15px;
    }

    div.border_bottom_left {
        width: 150px;
        height: 150px;
        bottom: -22px;
        left: -23.5px;
    }

    div.bbl_left_ball {
        top: 15px;
        right: 15px;
    }

    div.bbl_right_ball {
        top: 15px;
        left: 15px;
    }


    a.fullDBbutton {
        transition: all .8s;
        bottom: 34%;
        font-size: 22px;
        padding: 8px 14px;
    }

    a.fullDBbutton:hover {
        color: #edf3f7;
    }
    
    a.fullDBbutton::before {
        transition: all .8s ease-in-out;
        content: '';
        background: #1071b2;
        height: 100%;
        width: 0%;
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        z-index: -1;
        /* transform: translateX(-100%); */
    }
    
    a.fullDBbutton:hover::before {
        width: 100%;
        /* transform: translateX(0%); */
    }
    
}