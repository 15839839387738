
div.container {
    /* max-width: 414px; */
    /* width: 100vw; */
    /* height: 100px; */
    background: var(--grey-1);
    border: 2px solid var(--orange-6);
    position: absolute;
}

div.categorySingle {
    font-size: 14px;
    padding: 2.5px 10px;
}


div.categorySingle:active {
    background: var(--blue-1);
}