div.toggleDisplayContainer {
    display: flex;
    margin: 0 auto 20px;
    width: 50%;
    max-width: 240px;
    justify-content: space-around;
    padding: 0 1em;
}

/* I specifically left of the "div" prefix for this selector 
so the active class will have greater selector specificity*/
.toggleDisplayContainer i {
    font-size: 30px;
    color: var(--orange-6);
    padding: 4px;
    background: var(--blue-6);
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

i.active {
    color: var(--blue-6);
    background: var(--orange-6);
    border: 4px solid var(--blue-6);
}

