/* DEFAULT STYLING - for mobile devices (mobile-first design) */

div#sectionContainer {
    /* height: 866px; */
    text-align: center;
}

div#sectionContainer h1 {
    margin-top: 30px;
}

/* ********* we still have an overflow problem. something is causing extra width in the screen */

/* outer container is for the purpose of containing the gradient overlay.
gradient is fixed relative to its container, but this is only because of 
outer container. see  https://stackoverflow.com/questions/5209814/can-i-position-an-element-fixed-relative-to-parent/38796408#38796408 2016 Update for more info*/
div.outerContainer {
    transform: translateZ(0);
    overflow: hidden;
}

div#contentContainer {
    display: flex;
    margin-top: 35px;
    overflow-y: auto;
    width: 100%;
    margin-left: 38px;
    /* margin-bottom: 44px; */
    margin-bottom: 37px; /* -7 from its bottom margin to account for the 7px of bottom padding on the thumbnails */
    gap: 30px;
    /* position: relative; */
}

div.gradient {
    position: fixed;
    height: 100%;
    width: 45vw;
    background: linear-gradient(270deg, #FDFDFD 2.08%, rgba(253, 253, 253, 0.75) 49.74%, rgba(253, 253, 253, 0) 100%);;
    z-index: 100;
    right: 0;
    pointer-events: none;
}

div.thumbnail {
    padding-bottom: 7px; /* so box shadow isn't cut off */
}

div.thumbnail img {
    height: 200px;
    width: 200px;
}

div.thumbnail div.songName {
    font-size: 18px;
    margin: 10px 0;
}


/* TABLET - greater than 414px */
@media screen and (min-width: 414px) {
    div#sectionContainer h1 {
        margin-top: 35px;
    }

}

/* DESKTOP - greater than 1024px */

@media screen and (min-width: 1024px) {
    div#sectionContainer h1 {
        margin-top: 45px;
    }

    div.outerContainer {
        margin: 45px auto 40px;
        max-width: 1320px;
        padding-bottom: 7px;
    }

    div#contentContainer {
        display: grid;
        margin: 0 20px;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-evenly;
        row-gap: 40px;
    }

    div.gradient {
        display: none;
    }

    button.viewChordsBtn {
        margin-top: 65px;

    }
}
